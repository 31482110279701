import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import File from 'components/File';
import {
  getSharedBookingFiles,
  getRecentlySharedBookingFiles,
} from 'actions/files';

const styles = () => ({
  files: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '20px 0px',
    maxHeight: 625,
    overflowY: 'auto',
  },
  emptyFiles: {
    display: 'flex',
    padding: '20px 0',
    minHeight: 400,
    fontSize: 14,
  },
});

class Files extends Component {
  state = {
    files: [],
  }

  componentDidMount() {
    const { bookingId } = this.props;

    if (bookingId) {
      this.props.getSharedBookingFiles(bookingId).then(files => {
        this.setState({ files });
      });
    } else {
      this.props.getRecentlySharedBookingFiles().then(files => {
        this.setState({ files });
      });
    }
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      files,
    } = this.state;

    return (
      files.length > 0 ?
        <div className={classes.files}>
          {files.map(file => {
            return <File file={file} key={file.id} />;
          })}
        </div> :
        <div className={classes.emptyFiles}>There are no shared files.</div>
    );
  }
}

const mapDispatchToProps = {
  getSharedBookingFiles,
  getRecentlySharedBookingFiles,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(Files));
